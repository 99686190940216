import React, { useEffect, useRef, useState } from "react";

import classNames from "classnames";
import { css, StyleSheet } from "aphrodite";
import Form from "react-bootstrap/Form";

import useIncidentContext from "lib/hooks/useIncidentContext";
import useTemplateEditingContext from "lib/hooks/useTemplateEditingContext";

function DomesticSuspect() {
  const contentEditableRef = useRef(null);
  const { isEditing } = useTemplateEditingContext();
  const { generatedResult } = useIncidentContext();

  const [formData, setFormData] = useState({
    suspectStatement: "",
    form71030Completed: "",
  });

  useEffect(() => {
    if (generatedResult && generatedResult["Suspect Interview"]) {
      const suspectInterview = generatedResult["Suspect Interview"] || "";
      setFormData({
        suspectStatement: `${suspectInterview["suspect-words-before-arrest"]} ${suspectInterview["suspect-words-before-arrest"]}`,
        form71030Completed: generatedResult["710.30 completed"] || "",
      });
    }
  }, [generatedResult]);

  const handleInputChange = (field: string, value: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleUserText = (input: string) => {
    setFormData((prevData) => ({
      ...prevData,
      suspectStatement: input,
    }));
  };

  const handleInput = (e: React.FormEvent<HTMLDivElement>) => {
    handleUserText(e.currentTarget.innerHTML);
  };

  const handlePaste = (e: React.ClipboardEvent) => {
    e.preventDefault();
    const text = e.clipboardData.getData("text");
    insertTextAtCursor(text);
  };

  const insertTextAtCursor = (text: string) => {
    const sel = window.getSelection();
    if (sel && sel.rangeCount > 0) {
      const range = sel.getRangeAt(0);
      range.deleteContents();
      range.insertNode(document.createTextNode(text));
      range.collapse(false);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  };

  useEffect(() => {
    if (contentEditableRef.current) {
      const range = document.createRange();
      const sel = window.getSelection();
      range.selectNodeContents(contentEditableRef.current);
      range.collapse(false);
      if (sel) {
        sel.removeAllRanges();
        sel.addRange(range);
      }
    }
  }, [formData.suspectStatement]);

  return (
    <div className="d-flex flex-column w-100 border-start border-bottom border-end border-black border-3 p-0">
      <div className="d-flex justify-content-center align-items-center border-start border-end border-bottom border-black border-3">
        <span className="no-wrap">Suspect</span>
      </div>
      <div className="d-flex">
        <div className="d-flex flex-column w-100 border-start border-black border-3">
          <div className="border-bottom border-black">
            <div className={classNames(css(styles.normalFont), "d-flex p-1")}>What did the SUSPECT say (Before and After Arrest) :</div>
            <div
              className={classNames(css(styles.normalFont, styles.textAreaDiv), "border-0")}
              contentEditable={isEditing}
              ref={contentEditableRef}
              onInput={handleInput}
              onPaste={handlePaste}
              dangerouslySetInnerHTML={{ __html: formData.suspectStatement }}
            ></div>
          </div>
          <div className="d-flex flex-row border-bottom border-black">
            <div
              className={classNames(
                css(styles.normalFont),
                "d-flex flex-row justify-content-start flex-wrap p-0 flex-fill border-end border-black col-3"
              )}
            >
              <div className="d-flex p-1">710.30 completed? </div>
              <div className="d-flex p-1">
                <Form.Check
                  type="checkbox"
                  label="Yes"
                  className="m-0"
                  checked={formData.form71030Completed === "Yes"}
                  onChange={() => handleInputChange("form71030Completed", "Yes")}
                  disabled={!isEditing}
                />
              </div>
              <div className="d-flex p-1">
                <Form.Check
                  type="checkbox"
                  label="No"
                  className="m-0"
                  checked={formData.form71030Completed === "No"}
                  onChange={() => handleInputChange("form71030Completed", "No")}
                  disabled={!isEditing}
                />
              </div>
            </div>
            <div className="col-9"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  normalFont: {
    fontSize: "0.75rem",
  },
  textAreaDiv: {
    width: "100%",
    height: "auto",
    minHeight: "4.2em",
    resize: "none",
    padding: "0.5em",
    overflowY: "auto",
    borderWidth: 0,
  },
});

export default DomesticSuspect;
