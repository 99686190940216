//@ts-nocheck
import React, { useEffect, useState } from "react";

import classNames from "classnames";
import { css, StyleSheet } from "aphrodite";
import Form from "react-bootstrap/Form";

import DomesticInputBox from "components/domesticTemplate/domesticTemplatePage1/domesticInputBox";
import DomesticVictimP1 from "components/domesticTemplate/domesticTemplatePage1/domesticVictimP1";
import InputField from "components/domesticTemplate/domesticTemplatePage1/inputField";
import useIncidentContext from "lib/hooks/useIncidentContext";
import useTemplateEditingContext from "lib/hooks/useTemplateEditingContext";

function DomesticSuspectP2() {
  const { isEditing } = useTemplateEditingContext();
  const { generatedResult } = useIncidentContext();

  const [formData, setFormData] = useState({
    name: "",
    address: "",
    cityStateZip: "",
    contactMethod: "",
    dob: "",
    age: "",
    selfIdentified: "",
    gender: "",
    victimPhoneNumber: "",
    language: "",
    race: "",
    raceDiscription: "",
    ethnicity: "",
    liveTogether: undefined,
    suspectPresent: undefined,
    suspectInjured: undefined,
    injuryDescription: "",
    drugAlcoholUse: undefined,
    suspectSupervised: "",
    relationshipToVictim: "",
    relationshipToVictimDiscription: "",
    childInCommon: undefined,
    other: "",
  });

  type FormDataType = {
    name: string;
    address: string;
    cityStateZip: string;
    contactMethod: string;
    dob: string;
    age: string;
    selfIdentified: string;
    gender: string;
    victimPhoneNumber: string;
    language: string;
    race: string;
    raceDiscription: string;
    ethnicity: string;
    liveTogether?: boolean;
    suspectPresent?: boolean;
    suspectInjured?: boolean;
    injuryDescription: string;
    drugAlcoholUse?: boolean;
    suspectSupervised: string;
    relationshipToVictim: string;
    relationshipToVictimDiscription: string;
    childInCommon?: boolean;
    other: string;
  };

  useEffect(() => {
    if (generatedResult && generatedResult["suspect"]) {
      const suspect = generatedResult["suspect"] || "";
      setFormData({
        name: suspect["name"],
        address: `${suspect["address"]["Street No"]} ${suspect["address"]["Street Name"]} ${suspect["address"]["Blding. No"]} ${suspect["address"]["Apt No"]}`,
        cityStateZip: `${suspect["address"]["City"] || ""}  ${suspect["address"]["State"] || ""}  ${suspect["address"]["Zip"] || ""}`,
        contactMethod: suspect["safeContact"],
        dob: suspect["birthDate"],
        age: suspect["age"],
        selfIdentified: suspect["self-Identified"],
        gender: suspect["gender"],
        victimPhoneNumber: suspect["PhoneNumber"],
        language: suspect["Language"],
        race: suspect["race"],
        raceDiscription: suspect["race Description"],
        ethnicity: suspect["ethnicity"],
        liveTogether: suspect["do-suspect-and-victim-live-together"] === "Yes" ? true :
                     suspect["do-suspect-and-victim-live-together"] === "No" ? false : undefined,
        suspectPresent: suspect["Suspect-present"] === "Yes" ? true :
                       suspect["Suspect-present"] === "No" ? false : undefined,
        suspectInjured: suspect["was-suspect injured"] === "Yes" ? true :
                       suspect["was-suspect injured"] === "No" ? false : undefined,
        injuryDescription: suspect["injury description"],
        drugAlcoholUse: suspect["Possible-drug-or-alcohol"] === "Yes" ? true :
                       suspect["Possible-drug-or-alcohol"] === "No" ? false : undefined,
        suspectSupervised: suspect["Suspect-supervised"],
        relationshipToVictim: suspect["Suspect-relationship-to-victim"],
        relationshipToVictimDiscription: suspect["relativeDiscription"],
        childInCommon: suspect["Do the suspect and victim have a child in common"] === "Yes" ? true :
                      suspect["Do the suspect and victim have a child in common"] === "No" ? false : undefined,
        other: suspect["otherDescription"],
      });
    }
  }, [generatedResult]);

  const handleInputChange = (field: any, value: any) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };
  return (
    <div className="p-0 border-top border-black border-bottom border-start border-3 d-flex flex-column">
      <div className="d-flex justify-content-center align-items-center border-start border-end border-bottom border-black border-3">
        <span className="no-wrap">Suspect(P2)</span>
      </div>
      <div className="d-flex flex-row w-100 border-end border-bottom border-start border-black border-3 p-0">
        <div className="d-flex flex-column w-100">
          <div className="d-flex">
            <div className="col-7 border-start border-black border-3">
              <div className="border-end border-bottom border-black">
                <InputField
                  inputFieldText="Name (Last, First, M.I.) (Include Aliases)"
                  defaultValue={formData.name}
                  userInputHandler={handleInputChange}
                  field={"name"}
                />
              </div>
              <div className="border-end border-bottom border-black">
                <InputField
                  inputFieldText="Address (Street No., Street Name, Bldg. No., Apt No.)"
                  defaultValue={formData.address}
                  userInputHandler={handleInputChange}
                  field={"address"}
                />
              </div>
              <div className="border-end border-bottom border-black">
                <InputField
                  inputFieldText="City, State, Zip "
                  defaultValue={formData.cityStateZip}
                  userInputHandler={handleInputChange}
                  field={"cityStateZip"}
                />
              </div>
              <div className="border-end border-bottom border-black"></div>
            </div>
            <div className="col-5 d-flex flex-column justify-content-around h-100 p-0">
              <div className="d-flex flex-row p-0 m-0">
                <div className="col-5">
                  <div className="d-flex flex-row">
                    <div className="border-end border-bottom border-black w-75">
                      <InputField inputFieldText="DOB (MM/DD/YYYY)" defaultValue={formData.dob} userInputHandler={handleInputChange} field={"dob"} />
                    </div>
                    <div className="border-end border-bottom border-black w-25">
                      <InputField inputFieldText="Age" defaultValue={formData.age} userInputHandler={handleInputChange} field={"age"} />
                    </div>
                  </div>
                </div>

                <div
                  className={classNames(
                    css(styles.normalFont),
                    "border-end border-bottom border-black d-flex flex-row align-content-start flex-wrap p-0 col-7"
                  )}
                >
                  <div className="d-flex pe-1 h-50">
                    <Form.Check
                      type="checkbox"
                      label="Female"
                      className="m-0"
                      checked={formData.gender == "Female"}
                      onChange={() => handleInputChange("gender", "Female")}
                      disabled={!isEditing}
                    />
                  </div>
                  <div className="d-flex pe-1">
                    <Form.Check
                      type="checkbox"
                      label="Male"
                      className="m-0"
                      checked={formData.gender == "Male"}
                      onChange={() => handleInputChange("gender", "Male")}
                      disabled={!isEditing}
                    />
                  </div>
                  <div className="d-flex pe-1">
                    <Form.Check
                      type="checkbox"
                      label="Self-Identified:"
                      className="m-0"
                      checked={formData.gender == "Self-Identified:"}
                      onChange={() => handleInputChange("gender", "Self-Identified:")}
                      readOnly={!isEditing}
                    />
                  </div>
                  <div className="d-flex pe-1">
                    <DomesticInputBox defaultValue={formData.selfIdentified} userInputHandler={handleInputChange} inputFieldText="selfIdentified" />
                  </div>
                </div>
              </div>

              <div className="d-flex flex-row">
                <div className="col-5">
                  <div className="d-flex flex-row border-end border-bottom border-black">
                    <InputField
                      inputFieldText="Suspect Phone Number:"
                      defaultValue={formData.victimPhoneNumber}
                      userInputHandler={handleInputChange}
                      field={"victimPhoneNumber"}
                    />
                  </div>
                </div>

                <div className="col-7">
                  <div className="d-flex flex-row border-end border-bottom border-black">
                    <InputField inputFieldText="Language:" defaultValue={formData.language} userInputHandler={handleInputChange} field={"language"} />
                  </div>
                </div>
              </div>

              <div className="d-flex flex-row flex-fill">
                <div
                  className={classNames(
                    css(styles.normalFont),
                    "border-end border-bottom border-black d-flex flex-row align-content-start flex-wrap p-0 col-5"
                  )}
                >
                  <div className="d-flex pe-1 h-50">
                    <Form.Check
                      type="checkbox"
                      label="White"
                      className="m-0"
                      checked={formData.ethnicity == "White"}
                      onChange={() => handleInputChange("ethnicity", "White")}
                      disabled={!isEditing}
                    />
                  </div>
                  <div className="d-flex pe-1 h-50">
                    <Form.Check
                      type="checkbox"
                      label="Black"
                      className="m-0"
                      checked={formData.ethnicity == "Black"}
                      onChange={() => handleInputChange("ethnicity", "Black")}
                      disabled={!isEditing}
                    />
                  </div>
                  <div className="d-flex pe-1">
                    <Form.Check
                      type="checkbox"
                      label="Asian"
                      className="m-0"
                      checked={formData.ethnicity == "Asian"}
                      onChange={() => handleInputChange("ethnicity", "Asian")}
                      disabled={!isEditing}
                    />
                  </div>
                  <div className="d-flex pe-1">
                    <Form.Check
                      type="checkbox"
                      label="American Indian"
                      className="m-0"
                      checked={formData.ethnicity == "American Indian"}
                      onChange={() => handleInputChange("ethnicity", "American Indian")}
                      disabled={!isEditing}
                    />
                  </div>
                  <div className="d-flex pe-1">
                    <Form.Check
                      type="checkbox"
                      label="Other"
                      className="m-0"
                      checked={formData.ethnicity == "Other"}
                      onChange={() => handleInputChange("ethnicity", "Other")}
                      disabled={!isEditing}
                    />
                  </div>
                </div>

                <div
                  className={classNames(
                    css(styles.normalFont),
                    "border-end border-bottom border-black d-flex flex-row align-content-start flex-wrap p-0 col-7"
                  )}
                >
                  <div className="d-flex pe-1 h-50">
                    <Form.Check
                      type="checkbox"
                      label="Hispanic"
                      className="m-0"
                      checked={formData.race == "Hispanic"}
                      onChange={() => handleInputChange("race", "Hispanic")}
                      disabled={!isEditing}
                    />
                  </div>
                  <div className="d-flex pe-1">
                    <Form.Check
                      type="checkbox"
                      label="Non Hispanic"
                      className="m-0"
                      checked={formData.race == "Non-Hispanic"}
                      onChange={() => handleInputChange("race", "Non-Hispanic")}
                      disabled={!isEditing}
                    />
                  </div>
                  <div className="d-flex pe-1">
                    <Form.Check
                      type="checkbox"
                      label="Unknown"
                      className="m-0"
                      checked={formData.race == "Unknown"}
                      onChange={() => handleInputChange("race", "Unknown")}
                      disabled={!isEditing}
                    />
                  </div>
                  <div className="d-flex pe-1">
                    <DomesticInputBox defaultValue={formData.raceDiscription} userInputHandler={handleInputChange} inputFieldText="raceDiscription" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-row">
            <div className="col-7 border-start border-black border-3 d-flex flex-row h-100">
              <div className="border-end border-bottom border-black col-4 d-flex flex-fill">
                <div
                  className={classNames(
                    css(styles.normalFont),
                    "border-end border-bottom border-black d-flex flex-row align-content-start flex-wrap p-0"
                  )}
                >
                  <div className="d-flex">Do suspect and victim live together ?</div>
                  <div className="d-flex pe-1 h-50">
                    <Form.Check
                      type="checkbox"
                      label="Yes"
                      className="m-0"
                      checked={formData.liveTogether === true}
                      onChange={() => handleInputChange("liveTogether", true)}
                      disabled={!isEditing}
                    />
                  </div>
                  <div className="d-flex pe-1">
                    <Form.Check
                      type="checkbox"
                      label="No"
                      className="m-0"
                      checked={formData.liveTogether === false}
                      onChange={() => handleInputChange("liveTogether", false)}
                      disabled={!isEditing}
                    />
                  </div>
                </div>
              </div>
              <div className="border-end border-bottom border-black col-3 d-flex flex-fill">
                <div className={classNames(css(styles.normalFont), "d-flex")}>Suspect/P2 present?</div>
                <div
                  className={classNames(
                    css(styles.normalFont),
                    "border-end border-bottom border-black d-flex flex-row align-content-start flex-wrap p-0"
                  )}
                >
                  <div className="d-flex pe-1 h-50">
                    <Form.Check
                      type="checkbox"
                      label="Yes"
                      className="m-0"
                      checked={formData.suspectPresent === true}
                      onChange={() => handleInputChange("suspectPresent", true)}
                      disabled={!isEditing}
                    />
                  </div>
                  <div className="d-flex pe-1">
                    <Form.Check
                      type="checkbox"
                      label="No"
                      className="m-0"
                      checked={formData.suspectPresent === false}
                      onChange={() => handleInputChange("suspectPresent", false)}
                      disabled={!isEditing}
                    />
                  </div>
                </div>
              </div>
              <div className="border-end border-bottom border-black col-5 d-flex flex-fill">
                <div
                  className={classNames(
                    css(styles.normalFont),
                    "border-end border-bottom border-black d-flex flex-row align-content-start flex-wrap p-0"
                  )}
                >
                  <div className="d-flex">Was suspect injured?</div>
                  <div className="d-flex pe-1 h-50">
                    <Form.Check
                      type="checkbox"
                      label="Yes"
                      className="m-0"
                      checked={formData.suspectInjured === true}
                      onChange={() => handleInputChange("suspectInjured", true)}
                      disabled={!isEditing}
                    />
                  </div>
                  <div className="d-flex pe-1">
                    <Form.Check
                      type="checkbox"
                      label="No"
                      className="m-0"
                      checked={formData.suspectInjured === false}
                      onChange={() => handleInputChange("suspectInjured", false)}
                      disabled={!isEditing}
                    />
                  </div>

                  <div className="d-flex"> If yes describe:</div>
                  <div className="d-flex">
                    <DomesticInputBox
                      defaultValue={formData.injuryDescription}
                      userInputHandler={handleInputChange}
                      inputFieldText="injuryDescription"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-5 d-flex flex-row justify-content-around h-100 p-0">
              <div className="border-end border-bottom border-black col-4 d-flex flex-fill">
                <div
                  className={classNames(
                    css(styles.normalFont),
                    "border-end border-bottom border-black d-flex flex-row align-content-start flex-wrap p-0"
                  )}
                >
                  <div className={classNames(css(styles.normalFont), "d-flex")}>Possible drug or alcohol use?</div>
                  <div className="d-flex pe-1 h-50">
                    <Form.Check
                      type="checkbox"
                      label="Yes"
                      className="m-0"
                      checked={formData.drugAlcoholUse === true}
                      onChange={() => handleInputChange("drugAlcoholUse", true)}
                      disabled={!isEditing}
                    />
                  </div>
                  <div className="d-flex pe-1">
                    <Form.Check
                      type="checkbox"
                      label="No"
                      className="m-0"
                      checked={formData.drugAlcoholUse === false}
                      onChange={() => handleInputChange("drugAlcoholUse", false)}
                      disabled={!isEditing}
                    />
                  </div>
                </div>
              </div>
              <div className="border-end border-bottom border-black d-flex flex-fill col-8">
                <div
                  className={classNames(
                    css(styles.normalFont),
                    "border-end border-bottom border-black d-flex flex-row align-content-start flex-wrap p-0"
                  )}
                >
                  <div className="d-flex">Suspect supervised?</div>
                  <div className="d-flex pe-1 h-50">
                    <Form.Check
                      type="checkbox"
                      label="Probation"
                      className="m-0"
                      checked={formData.suspectSupervised === "Probation"}
                      onChange={() => handleInputChange("suspectSupervised", "Probation")}
                      disabled={!isEditing}
                    />
                  </div>
                  <div className="d-flex pe-1">
                    <Form.Check
                      type="checkbox"
                      label="Parole"
                      className="m-0"
                      checked={formData.suspectSupervised === "Parole"}
                      onChange={() => handleInputChange("suspectSupervised", "Parole")}
                      disabled={!isEditing}
                    />
                  </div>
                  <div className="d-flex pe-1">
                    <Form.Check
                      type="checkbox"
                      label="Not Supervised"
                      className="m-0"
                      checked={formData.suspectSupervised === "Not Supervised"}
                      onChange={() => handleInputChange("suspectSupervised", "Not Supervised")}
                      disabled={!isEditing}
                    />
                  </div>
                  <div className="d-flex pe-1">
                    <Form.Check
                      type="checkbox"
                      label=" Status Unknown"
                      className="m-0"
                      checked={formData.suspectSupervised === "Status Unknown"}
                      onChange={() => handleInputChange("suspectSupervised", "Status Unknown")}
                      disabled={!isEditing}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-row">
            <div className="border-start border-black border-3 col-9 d-flex">
              <div
                className={classNames(
                  css(styles.normalFont),
                  "border-end border-bottom border-black d-flex flex-row flex-fill align-content-start flex-wrap p-0"
                )}
              >
                <div className="d-flex">Suspect (P2) Relationship to Victim (P1)</div>
                <div className="d-flex pe-1 h-50">
                  <Form.Check
                    type="checkbox"
                    label="Married"
                    className="m-0"
                    checked={formData.relationshipToVictim === "Married"}
                    onChange={() => handleInputChange("relationshipToVictim", "Married")}
                    disabled={!isEditing}
                  />
                </div>
                <div className="d-flex pe-1">
                  <Form.Check
                    type="checkbox"
                    label="Intimate Partner/Dating"
                    className="m-0"
                    checked={formData.relationshipToVictim === "Intimate Partner/Dating"}
                    onChange={() => handleInputChange("relationshipToVictim", "Intimate Partner/Dating")}
                    disabled={!isEditing}
                  />
                </div>
                <div className="d-flex pe-1">
                  <Form.Check
                    type="checkbox"
                    label="Formerly Married"
                    className="m-0"
                    checked={formData.relationshipToVictim === "Formerly Married"}
                    onChange={() => handleInputChange("relationshipToVictim", "Formerly Married")}
                    disabled={!isEditing}
                  />
                </div>
                <div className="d-flex pe-1">
                  <Form.Check
                    type="checkbox"
                    label="Former Intimate Partner"
                    className="m-0"
                    checked={formData.relationshipToVictim === "Former Intimate Partner"}
                    onChange={() => handleInputChange("relationshipToVictim", "Former Intimate Partner")}
                    disabled={!isEditing}
                  />
                </div>
                <div className="d-flex pe-1">
                  <Form.Check
                    type="checkbox"
                    label="Parent of Victim (P1)"
                    className="m-0"
                    checked={formData.relationshipToVictim === "Parent of Victim (P1)"}
                    onChange={() => handleInputChange("relationshipToVictim", "Parent of Victim (P1)")}
                    disabled={!isEditing}
                  />
                </div>
                <div className="d-flex pe-1">
                  <Form.Check
                    type="checkbox"
                    label="Child of Victim"
                    className="m-0"
                    checked={formData.relationshipToVictim === "Child of Victim"}
                    onChange={() => handleInputChange("relationshipToVictim", "Child of Victim")}
                    disabled={!isEditing}
                  />
                </div>
                <div className="d-flex pe-1">
                  <Form.Check
                    type="checkbox"
                    label="Relative"
                    className="m-0"
                    checked={formData.relationshipToVictim === "Relative"}
                    onChange={() => handleInputChange("relationshipToVictim", "Relative")}
                    disabled={!isEditing}
                  />
                </div>

                <div className="d-flex">
                  <DomesticInputBox
                    defaultValue={formData.relationshipToVictimDiscription}
                    userInputHandler={handleInputChange}
                    inputFieldText="relationshipToVictimDiscription"
                  />
                </div>
                <div className="d-flex pe-1">
                  <Form.Check
                    type="checkbox"
                    label="Other"
                    className="m-0"
                    checked={formData.relationshipToVictim === "Other"}
                    onChange={() => handleInputChange("relationshipToVictim", "Other")}
                    disabled={!isEditing}
                  />
                </div>

                <div className="d-flex">
                  <DomesticInputBox defaultValue={formData.other} userInputHandler={handleInputChange} inputFieldText="other" />
                </div>
              </div>
            </div>

            <div className="border-end border-bottom border-black d-flex flex-fill col-3">
              <div
                className={classNames(
                  css(styles.normalFont),
                  "border-end border-bottom border-black d-flex flex-row align-content-start flex-wrap p-0"
                )}
              >
                <div className="d-flex">Do the suspect and victim have a child in common?</div>
                <div className="d-flex pe-1 h-50">
                  <Form.Check
                    type="checkbox"
                    label="Yes"
                    className="m-0"
                    checked={formData.childInCommon === true}
                    onChange={() => handleInputChange("childInCommon", true)}
                    disabled={!isEditing}
                  />
                </div>
                <div className="d-flex pe-1">
                  <Form.Check
                    type="checkbox"
                    label="No"
                    className="m-0"
                    checked={formData.childInCommon === false}
                    onChange={() => handleInputChange("childInCommon", false)}
                    disabled={!isEditing}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const styles = StyleSheet.create({
  normalFont: {
    fontSize: "0.75rem",
  },
});

export default DomesticSuspectP2;
