//@ts-nocheck
import React, { useEffect, useRef, useState } from "react";

import classNames from "classnames";
import { css, StyleSheet } from "aphrodite";
import Form from "react-bootstrap/Form";
import InputField from "components/domesticTemplate/domesticTemplatePage1/inputField";

import DomesticInputBox from "components/domesticTemplate/domesticTemplatePage1/domesticInputBox";
import ReverseColumnInputField from "components/domesticTemplate/domesticTemplatePage1/reverseColumnInputField";
import useIncidentContext from "lib/hooks/useIncidentContext";
import useTemplateEditingContext from "lib/hooks/useTemplateEditingContext";

function DomesticSupportingDeposition() {
  const contentEditableRef = useRef(null);
  const { isEditing } = useTemplateEditingContext();
  const { generatedResult } = useIncidentContext();
  const [formState, setFormState] = useState({
    suspectName: "",
    victimName: "",
    date: "",
    location: "",
    time: "",
    incidentDescription: "",
    interpreterServiceProvider: "",
    interpreterRequested: undefined,
    interpreterUsed: undefined,
    victimDeponentSignatureDate: "",
    witnessOrOfficerSignatureDate: "",
    interpreterSignatureDate: "",
    pageCount: "",
    pageNumber: "",
    VictimDeponentSignature: "",
    WitnessOrOfficerSignature: "",
  });

  useEffect(() => {
    if (generatedResult) {
      const supportingDeposition = generatedResult["Supporting deposition"] || "";
      const suspect = generatedResult["suspect"] || "";
      const victim = generatedResult["Victim"] || "";
      const incidentData = generatedResult["Incident"] || "";

      setFormState({
        suspectName: suspect["name"],
        victimName: victim["name"],
        date: incidentData["occuredDate"],
        location: `${incidentData["address"]["Street No"]} ${incidentData["address"]["Street Name"]} ${incidentData["address"]["Blding. No"]} ${incidentData["address"]["Apt No"]}`,
        time: incidentData["occuredTime"],
        incidentDescription: supportingDeposition["incident description"],
        interpreterServiceProvider: supportingDeposition[""],
        interpreterRequested: supportingDeposition["Interpreter Requested"] === "Yes" ? true :
                            supportingDeposition["Interpreter Requested"] === "No" ? false : undefined,
        interpreterUsed: supportingDeposition["Interpreter Used"] === "Yes" ? true :
                       supportingDeposition["Interpreter Used"] === "No" ? false : undefined,
        victimDeponentSignatureDate: supportingDeposition[""],
        witnessOrOfficerSignatureDate: supportingDeposition[""],
        interpreterSignatureDate: supportingDeposition[""],
        pageCount: supportingDeposition[""],
        pageNumber: supportingDeposition[""],
        VictimDeponentSignature: "",
        WitnessOrOfficerSignature: "",
      });
    }
  }, [generatedResult]);

  const handleInputChange = (field: any, value: any) => {
    setFormState({
      ...formState,
      [field]: value,
    });
  };
  const handleUserText = (input: any) => {
    setFormState({
      ...formState,
      ["incidentDescription"]: input,
    });
  };
  const handleInput = (e: any) => {
    handleUserText(e.currentTarget.innerHTML);
  };

  const handlePaste = (e: any) => {
    e.preventDefault();
    const text = e.clipboardData.getData("text");
    insertTextAtCursor(text);
  };

  const insertTextAtCursor = (text: any) => {
    const sel = window.getSelection();
    if (sel && sel.rangeCount > 0) {
      const range = sel.getRangeAt(0);
      range.deleteContents();
      range.insertNode(document.createTextNode(text));
      range.collapse(false);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  };

  useEffect(() => {
    if (contentEditableRef.current) {
      const range = document.createRange();
      const sel = window.getSelection();
      range.selectNodeContents(contentEditableRef.current);
      range.collapse(false);
      if (sel) {
        sel.removeAllRanges();
        sel.addRange(range);
      }
    }
  }, [formState.incidentDescription]);
  return (
    <div className="p-0 w-100 d-flex flex-fill">
      <div className="border-start border-end border-top border-black border-3 w-100">
        <div className="d-flex flex-column border-end border-bottom border-black">
          <div className="d-flex justify-content-center align-items-center fst-italic">
            <h6>STATEMENT OF ALLEGATIONS/SUPPORTING DEPOSITION</h6>
          </div>

          <div className={classNames(css(styles.normalFont), "d-flex")}>
            * Officers are encouraged to assist the Victim in completing this section of the form.
          </div>
        </div>

        <div className="border-bottom border-black d-flex flex-row">
          <div className="d-flex flex-row w-100">
            <InputField
              inputFieldText="Suspect Name (Last, First, M.I)"
              defaultValue={formState.suspectName}
              userInputHandler={handleInputChange}
              field={"suspectName"}
            />
          </div>
        </div>

        <div className="border-bottom border-black d-flex flex-column">
          <div className="d-flex flex-row">
            <div className="col-5 d-flex flex-row">
              <div className="d-flex pe-1">I</div>
              <div className="d-flex flex-fill">
                <DomesticInputBox defaultValue={formState.victimName} userInputHandler={handleInputChange} inputFieldText="victimName" />
              </div>
            </div>
            <div className="col-7 d-flex flex-row">
              <div className="d-flex pe-1">(Victim/Deponent Name) state that on</div>
              <div className="d-flex flex-fill">
                <DomesticInputBox defaultValue={formState.date} userInputHandler={handleInputChange} inputFieldText="date" />
              </div>
              <div className="d-flex pe-1">(Date)</div>
            </div>
          </div>
          <div className="d-flex flex-row">
            <div className="col-5 d-flex flex-row">
              <div className="d-flex pe-1">at</div>
              <div className="d-flex flex-fill">
                <DomesticInputBox defaultValue={formState.time} userInputHandler={handleInputChange} inputFieldText="time" />
              </div>
            </div>
            <div className="col-7 d-flex flex-row">
              <div className="w-50 d-flex">(Location of incident) in the County/City/Town/Village</div>
              <div className="w-50 d-flex flex-fill">
                <DomesticInputBox defaultValue={formState.location} userInputHandler={handleInputChange} inputFieldText="location" />
              </div>
            </div>
          </div>
          <div className="d-flex flex-row">
            <div className="col-7 d-flex flex-row">of the State of New York, the following did occur:</div>
          </div>

          <div
            className={classNames(css(styles.normalFont, styles.textAreaDiv), "border-0")}
            contentEditable={isEditing}
            ref={contentEditableRef}
            onInput={handleInput}
            onPaste={handlePaste}
            dangerouslySetInnerHTML={{ __html: formState.incidentDescription }}
          ></div>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <h6>False Statements made herein are punishable as a Class A Misdemeanor, pursuant to section 210.45 of the Penal Law</h6>
        </div>

        <div className="border-bottom border-black d-flex flex-row">
          <div className="d-flex flex-column col-6 p-2">
            <ReverseColumnInputField
              inputFieldText="Victim/Deponent Signature"
              defaultValue={formState.VictimDeponentSignature}
              userInputHandler={handleInputChange}
              field={"VictimDeponentSignature"}
            />
            <ReverseColumnInputField
              inputFieldText="Witness or Officer Signature"
              defaultValue={formState.WitnessOrOfficerSignature}
              userInputHandler={handleInputChange}
              field={"WitnessOrOfficerSignature"}
            />
            <ReverseColumnInputField
              inputFieldText="Interpreter Signature and Interpreter Service Provider Name"
              defaultValue={formState.interpreterServiceProvider}
              userInputHandler={handleInputChange}
              field={"interpreterServiceProvider"}
            />
            <div className={classNames(css(styles.normalFont), "d-flex flex-row justify-content-start flex-wrap p-0  p-1")}>
              <div className="d-flex">Interpreter Requested</div>
              <div className="d-flex pe-1 h-50">
                <Form.Check
                  type="checkbox"
                  label="Yes"
                  className="m-0"
                  checked={formState.interpreterRequested === true}
                  onChange={() => handleInputChange("interpreterRequested", true)}
                  disabled={!isEditing}
                />
              </div>
              <div className="d-flex pe-1">
                <Form.Check
                  type="checkbox"
                  label="No"
                  className="m-0"
                  checked={formState.interpreterRequested === false}
                  onChange={() => handleInputChange("interpreterRequested", false)}
                  disabled={!isEditing}
                />
              </div>
              <div className="d-flex pe-1">Interpreter Used</div>
              <div className="d-flex pe-1 h-50">
                <Form.Check
                  type="checkbox"
                  label="Yes"
                  className="m-0"
                  checked={formState.interpreterUsed === true}
                  onChange={() => handleInputChange("interpreterUsed", true)}
                  disabled={!isEditing}
                />
              </div>
              <div className="d-flex pe-1">
                <Form.Check
                  type="checkbox"
                  label="No"
                  className="m-0"
                  checked={formState.interpreterUsed === false}
                  onChange={() => handleInputChange("interpreterUsed", false)}
                  disabled={!isEditing}
                />
              </div>
            </div>
          </div>
          <div className="d-flex flex-row col-6 p-2">
            <div className="col-6 d-flex flex-column pe-3">
              <ReverseColumnInputField
                inputFieldText="Date"
                defaultValue={formState.victimDeponentSignatureDate}
                userInputHandler={handleInputChange}
                field={"victimDeponentSignatureDate"}
              />
              <ReverseColumnInputField
                inputFieldText="Date"
                defaultValue={formState.witnessOrOfficerSignatureDate}
                userInputHandler={handleInputChange}
                field={"witnessOrOfficerSignatureDate"}
              />
              <ReverseColumnInputField
                inputFieldText="Date"
                defaultValue={formState.interpreterSignatureDate}
                userInputHandler={handleInputChange}
                field={"interpreterSignatureDate"}
              />
            </div>
            <div className="col-6 d-flex flex-row">
              <div className="d-flex flex-row col-9">
                <div className="d-flex flex-column h-100 border border-2 border-dark me-2 p-2">
                  <h6 className="d-flex justify-content-center w-100">Note:</h6>
                  <div className="d-flex flex-wrap">Whether or not this form is signed, this DIR Form will be filed with Law Enforcement.</div>
                </div>
              </div>
              <div className="d-flex flex-column col-3">
                <div className="d-flex flex-column h-100 border border-1 border-dark me-2 ">
                  <div className="d-flex justify-content-center">Page</div>
                  <ReverseColumnInputField defaultValue={formState.pageNumber} userInputHandler={handleInputChange} field={"pageNumber"} />
                  <div className="d-flex justify-content-center">Of</div>
                  <ReverseColumnInputField defaultValue={formState.pageCount} userInputHandler={handleInputChange} field={"pageCount"} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  normalFont: {
    fontSize: "0.75rem",
  },
  textAreaDiv: {
    width: "100%",
    height: "auto",
    minHeight: 224,
    padding: 8,
    overflowY: "auto",
    borderWidth: 0,
  },
});

export default DomesticSupportingDeposition;
